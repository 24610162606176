import Vue from "vue";
import Options from "vue-class-component";
import { namespace } from "vuex-class";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "vuesax/dist/vuesax.css";

const Auth = namespace("Auth");

@Options({})
export default class LoginLayout extends Vue {
  private user: any = { username: "", password: "" };
  private email = "";
  private password = "";
  private loading = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Mutation
  private setBranchId!: (newValue: number) => void;

  @Auth.Action
  private login!: (data: any) => Promise<any>;

  created() {
    if (this.isLoggedIn) {
      // this.$router.push('/admin/dashboard');
    }
  }

  handleLogin(values: any) {
    this.loading = true;
    console.log(values);
    this.loading = true;
    (this as any).$Progress.start();
    if (this.email && this.password) {
      this.user.username = this.email;
      this.user.password = this.password;

      this.login(this.user).then(
        (data) => {
          this.loading = false;
          this.$router.push("/admin/dashboard");
          (this as any).$Progress.finish();
        },
        (error) => {
          this.loading = false;
          (this as any).$Progress.finish();
          (this as any).$vs.notify({
            title: "Login Failed",
            text: error,
            color: "danger",
            icon: " highlight_off",
          });
        }
      );
    } else {
      this.loading = false;
      return false;
    }
  }
}
